<template>
  <div class="about">
    <div class="about_mainContainer">
      <div class="about_main">
        <div class="about_left">
          <h3>硬件产品</h3>
          <p>林卫视</p>
          <p>林视界</p>
          <p>红外相机</p>
          <p>智慧大屏</p>
          <p>全景监测站</p>
        </div>
        <div class="about_middle">
          <h3>软件系统</h3>
          <p>科普宣教系统</p>
          <p>科研监测系统</p>
          <p>智慧保护地系统</p>
          <p>AI自动识别系统</p>
          <p>全景监测展示系统</p>
          <p>智慧林长综合管理平台</p>
          <p>红外相机监测自动识别系统</p>
          <p>网格化智能巡护监测管理系统</p>
        </div>
        <div class="about_right">
          <h3>联系我们</h3>
          <p style="display: flex;">
            <span style="display: block; width: 50px;">电话：</span>18818812566
          </p>
          <p style="display: flex;">
            <span style="display: block; width: 50px;">官网：</span>
            <a href="www.wggis.com">www.wggis.com</a>
          </p>
          <p style="display: flex;">
            <span style="display: block; width: 50px;">邮箱：</span>
            <a href="mailto:wangjun@wggis.com">wangjun@wggis.com</a>
          </p>
          <p style="display: flex;">
            <span style="display: block; width: 50px;">地址：</span>
            <span style="display: block; width: 240px;">广州市天河区合景路10号</span>
          </p>
          <div class="about_tencent">
            <div>
              <img src="./qr.png" />
              <div style="text-align: center;">公众号</div>
            </div>
            <div>
              <img src="./vw.jpg" />
              <div style="text-align: center;">小程序</div>
            </div>
          </div>
        </div>
        <div class="about_bottom">
          <p style="text-align: center;">
            ©2020-{{ new Date().getFullYear() }}广州网格智能科技有限公司|
            <a href="https://beian.miit.gov.cn/">粤ICP备20052949号</a>
          </p>
        </div>
      </div> 
    </div>
  </div>
</template>
<script lang="ts" setup>

</script>

<style lang="scss">
.about {
  position: relative;
  width: 100%;
  background-color: #ffffff;
  overflow: hidden;
  display: flex;
  flex-direction: column;


  &_main {
    width: 1058px;
    height: 400px;
    margin: auto;
    display: grid;
    grid-template-columns: 150px 230px minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr) 50px;
    grid-column-gap: 140px;
    grid-row-gap: 12px;
    padding: 24px 0 0 0;
  }
  &_left {
    grid-area: 1 / 1 / 2 / 2;
  }
  &_middle {
    grid-area: 1 / 2 / 2 / 3;
  }
  &_right {
    grid-area: 1 / 3 / 2 / 4;
    position: relative;
  }
  &_bottom {
    grid-area: 2 / 1 / 3 / 4;
  }

  &_left p,
  &_middle p,
  &_right p,
  &_main h3 {
    margin-bottom: 12px;
  }
  & h3 {
    color: #272727;
  }
  &_left p,
  &_middle p,
  &_right p {
    color: #4d4d4d;
  }
  &_tencent {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &_tencent img {
    width: 100px;
    height: 100px;
  }
}
@media screen  and (max-width: 1200px){
  .about_main {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    height: 700px;
  }
  .about_left {
    grid-area: auto;

  }
  .about_middle {
    grid-area: auto;
  }
  .about_right {
    grid-area: auto;
  }
  .about_bottom {
    grid-area: auto;
  }
}
@media screen  and (max-width: 768px){
  .about_main {
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    height: 700px;
    padding-left: 24px;
    height: 100%;
  }
  .about_tencent {
    display: flex;
    flex-direction: row;
  }
}
</style>